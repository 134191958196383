// LARGE DESKTOP & UP
// ------------------

@media (min-width: 1281px) {

  // Fixed grid
  #grid > .core(70px, 30px);

  // Fluid grid
  #grid > .fluid(5.982905983%, 2.564102564%);

  // Input grid
  #grid > .input(70px, 30px);

  // Thumbnails
  .thumbnails {
    margin-left: -30px;
  }
  .thumbnails > li {
    margin-left: 30px;
  }
  .row-fluid .thumbnails {
    margin-left: 0;
  }
}
