// UP TO LANDSCAPE PHONE
// ---------------------

@media (max-width: 480px) {

  // Smooth out the collapsing/expanding nav
  .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0); // activate the GPU
  }

  // Block level the page header small tag for readability
  .page-header h1 small {
    display: block;
    line-height: @baseLineHeight;
  }

  // Update checkboxes for iOS
  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid #ccc;
  }

  // Remove the horizontal form styles
  .form-horizontal .control-group > label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
  }
  // Move over all input controls and content
  .form-horizontal .controls {
    margin-left: 0;
  }
  // Move the options list down to align with labels
  .form-horizontal .control-list {
    padding-top: 0; // has to be padding because margin collaspes
  }
  // Move over buttons in .form-actions to align with .controls
  .form-horizontal .form-actions {
    padding-left: 10px;
    padding-right: 10px;
  }

  // Modals
  .modal {
    position: absolute;
    top:   10px;
    left:  10px;
    right: 10px;
    width: auto;
    margin: 0;
    &.fade.in { top: auto; }
  }
  .modal-header .close {
    padding: 10px;
    margin: -10px;
  }

  // Carousel
  .carousel-caption {
    position: static;
  }

}



// LANDSCAPE PHONE TO SMALL DESKTOP & PORTRAIT TABLET
// --------------------------------------------------

@media (max-width: 767px) {

  // Padding to set content in a bit
  body {
    padding-left: 20px;
    padding-right: 20px;
  }
  // Negative indent the now static "fixed" navbar
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    margin-left: -20px;
    margin-right: -20px;
  }
  // Remove padding on container given explicit padding set on body
  .container-fluid {
    padding: 0;
  }

  // TYPOGRAPHY
  // ----------
  // Reset horizontal dl
  .dl-horizontal {
    dt {
      float: none;
      clear: none;
      width: auto;
      text-align: left;
    }
    dd {
      margin-left: 0;
    }
  }

  // GRID & CONTAINERS
  // -----------------
  // Remove width from containers
  .container {
    width: auto;
  }
  // Fluid rows
  .row-fluid {
    width: 100%;
  }
  // Undo negative margin on rows and thumbnails
  .row,
  .thumbnails {
    margin-left: 0;
  }
  // Make all grid-sized elements block level again
  [class*="span"],
  .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: auto;
    margin-left: 0;
  }

  // FORM FIELDS
  // -----------
  // Make span* classes full width
  .input-large,
  .input-xlarge,
  .input-xxlarge,
  input[class*="span"],
  select[class*="span"],
  textarea[class*="span"],
  .uneditable-input {
    .input-block-level();
  }
  // But don't let it screw up prepend/append inputs
  .input-prepend input,
  .input-append input,
  .input-prepend input[class*="span"],
  .input-append input[class*="span"] {
    display: inline-block; // redeclare so they don't wrap to new lines
    width: auto;
  }

}
