// DROPDOWN MENUS
// --------------

// Use the .menu class on any <li> element within the topbar or ul.tabs and you'll get some superfancy dropdowns
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
  // The caret makes the toggle a bit too tall in IE7
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}

// Dropdown arrow/caret
// --------------------
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top:   4px solid @black;
  border-right: 4px solid transparent;
  border-left:  4px solid transparent;
  content: "";
  .opacity(30);
}

// Place the caret
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown:hover .caret,
.open .caret {
  .opacity(100);
}

// The dropdown menu (ul)
// ----------------------
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: @zindexDropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 160px;
  padding: 4px 0;
  margin: 1px 0 0; // override default ul
  list-style: none;
  background-color: @dropdownBackground;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  .border-radius(5px);
  .box-shadow(0 5px 10px rgba(0,0,0,.2));
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;

  // Aligns the dropdown menu to right
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    .nav-divider();
  }

  // Links within the dropdown menu
  a {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: @baseLineHeight;
    color: @dropdownLinkColor;
    white-space: nowrap;
  }
}

// Hover state
// -----------
.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  color: @dropdownLinkColorHover;
  text-decoration: none;
  background-color: @dropdownLinkBackgroundHover;
}

// Open state for the dropdown
// ---------------------------
.open {
  // IE7's z-index only goes to the nearest positioned ancestor, which would
  // make the menu appear below buttons that appeared later on the page
  *z-index: @zindexDropdown;

  .dropdown-menu {
    display: block;
  }
}

// Right aligned dropdowns
// ---------------------------
.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// ------------------------------------------------------
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?
.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    border-top: 0;
    border-bottom: 4px solid @black;
    content: "\2191";
  }
  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px;
  }
}

// Typeahead
// ---------
.typeahead {
  margin-top: 2px; // give it some space to breathe
  .border-radius(4px);
}
