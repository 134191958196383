// PORTRAIT TABLET TO DEFAULT DESKTOP
// ----------------------------------

@media (min-width: 768px) and (max-width: 979px) {

  // Fixed grid
  #grid > .core(42px, 20px);

  // Fluid grid
  #grid > .fluid(5.801104972%, 2.762430939%);

  // Input grid
  #grid > .input(42px, 20px);

  // No need to reset .thumbnails here since it's the same @gridGutterWidth

}
