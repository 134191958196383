// Scaffolding
// Basic and global styles for generating a grid system, structural layout, and page templates
// -------------------------------------------------------------------------------------------


// Body reset
// ----------

body {
  margin: 0;
  font-family: @baseFontFamily;
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  color: @textColor;
  background-color: @bodyBackground;
}


// Links
// -----

a {
  color: @linkColor;
  text-decoration: none;
}
a:hover {
  color: @linkColorHover;
  text-decoration: underline;
}
