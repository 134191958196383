// COMPONENT ANIMATIONS
// --------------------

.fade {
  .opacity(0);
  .transition(opacity .15s linear);
  &.in {
    .opacity(100);
  }
}

.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  .transition(height .35s ease);
  &.in {
    height: auto;
  }
}