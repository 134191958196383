// POPOVERS
// --------

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: @zindexPopover;
  display: none;
  padding: 5px;
  &.top    { margin-top:  -5px; }
  &.right  { margin-left:  5px; }
  &.bottom { margin-top:   5px; }
  &.left   { margin-left: -5px; }
  &.top .arrow    { #popoverArrow > .top(); }
  &.right .arrow  { #popoverArrow > .right(); }
  &.bottom .arrow { #popoverArrow > .bottom(); }
  &.left .arrow   { #popoverArrow > .left();  }
  .arrow {
    position: absolute;
    width: 0;
    height: 0;
  }
}
.popover-inner {
  padding: 3px;
  width: 280px;
  overflow: hidden;
  background: @black; // has to be full background declaration for IE fallback
  background: rgba(0,0,0,.8);
  .border-radius(6px);
  .box-shadow(0 3px 7px rgba(0,0,0,0.3));
}
.popover-title {
  padding: 9px 15px;
  line-height: 1;
  background-color: #f5f5f5;
  border-bottom:1px solid #eee;
  .border-radius(3px 3px 0 0);
}
.popover-content {
  padding: 14px;
  background-color: @white;
  .border-radius(0 0 3px 3px);
  .background-clip(padding-box);
  p, ul, ol {
    margin-bottom: 0;
  }
}
