// PAGER
// -----

.pager {
  margin-left: 0;
  margin-bottom: @baseLineHeight;
  list-style: none;
  text-align: center;
  .clearfix();
}
.pager li {
  display: inline;
}
.pager a {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  .border-radius(15px);
}
.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}
.pager .next a {
  float: right;
}
.pager .previous a {
  float: left;
}
.pager .disabled a,
.pager .disabled a:hover {
  color: @grayLight;
  background-color: #fff;
  cursor: default;
}