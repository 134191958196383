// PAGINATION
// ----------

.pagination {
  height: @baseLineHeight * 2;
  margin: @baseLineHeight 0;
 }
.pagination ul {
  display: inline-block;
  .ie7-inline-block();
  margin-left: 0;
  margin-bottom: 0;
  .border-radius(3px);
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}
.pagination li {
    display: inline;
  }
.pagination a {
  float: left;
  padding: 0 14px;
  line-height: (@baseLineHeight * 2) - 2;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}
.pagination a:hover,
.pagination .active a {
  background-color: #f5f5f5;
}
.pagination .active a {
  color: @grayLight;
  cursor: default;
}
.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: @grayLight;
  background-color: transparent;
  cursor: default;
}
.pagination li:first-child a {
  border-left-width: 1px;
  .border-radius(3px 0 0 3px);
}
.pagination li:last-child a {
  .border-radius(0 3px 3px 0);
}

// Centered
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}
